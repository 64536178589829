<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">当前积分：{{ integral }}</div>   
        <div class="rb1">
          <img src="@/assets/tj_1.png" class="icon" />
          <div class="text" @click="submit()">提交</div>
        </div>
        <div class="rb" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>  
      </div>
      <div class="content">
        <div class="items">
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box zz">
              <div class="name tt">
                <span class="roomcode f">{{ item.user_name }}</span>
              </div>
              <div class="name">
                <span class="region">{{ item.mobile }}</span>
              </div>
              <div class="name">
                <span class="region">{{ item.certificate_type }}</span>
              </div>
            </div>
            <div class="box zz">
              <div class="name">                              
                <img :src="item.picture" @click="photograph_jz(item)" class="imgpic" />
              </div>              
            </div>            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  async created() {
    //let { hrefurl } = this.$route.query;
    //this.hrefurl = hrefurl; 
    this.hrefurl = window.sessionStorage.getItem('hrefurl');
    let url = '';
    let ua = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      //url = location.href.split('#')[0];
      url = this.hrefurl;
      //alert("iphone"+url);
    }else{
      url = location.href;
      //alert("android:"+url);
    }
    //alert(url);
    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: url
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });
    
    //this.items = this.$route.query.nextitems;
    this.listIdNum = this.$route.query.listidnum;
    this.SiteCode = this.$route.query.SiteCode;
    this.ClientID = this.$route.query.ClientID;
    this.IdNum = this.$route.query.IdNum;

    this.query();
  },
  data() {
    return {
      integral: 0,
      selectItem: '1',
      selectQyItem: '3',
      roomname: '503',
      region: 'C区',
      floor: '5',
      enterdate: '2020-01-02',
      leavedate: '2020-01-05',
      days: '3',
      ClientID:'', 
      SiteCode:'',
      IdNum:'',
      listIdNum:'',
      hrefurl:'',
      state:false,
      qyitems: [
          {id:1, name: 'A区'},
          {id:2, name: 'B区'},
          {id:3, name: 'C区'}
      ],
      ztitems: [
          {id:1, name: '全部'},
          {id:2, name: '入住'},
          {id:3, name: '空闲'}
      ],
      items: [
        
      ]
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/tourist/select_peers', query:{SiteCode:this.SiteCode,ClientID:this.ClientID}});
    },
    photograph_jz(obj){
      //alert("选择照片");
      window.wx.ready(() => {
        //选择照片
        window.wx.chooseImage({
          count: 1, //张数， 默认9
          sizeType: ['compressed'], //建议压缩图
          sourceType: ['album', 'camera'], // 来源是相册(album)、相机(camera)
          //sourceType: ['camera'], // 来源是相册(album)、相机(camera)
          success: res => {
            var localIds = res.localIds.toString();

            //上传至微信服务器
            window.wx.uploadImage({
              localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
              success: res => {
                var serverId = res.serverId; // 返回图片的服务器端 ID          
                this.uploadImg_jz(serverId,obj);

              },
              fail: function() {
                Toast("上传异常");
              }
            });

          },
          fail: function() {
            Toast("选择异常");
          }
        }); 
      });
      
    },
    async uploadImg_jz(serverId,obj) {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_pz_jz',
        qs.stringify({
          pzjzid: serverId,
          mobile: obj.mobile,
          username: obj.user_name,
          IdNum : obj.certificate_code
        })
      );
      if (data.flag === '0') {         
        //obj.picture = data.data.pic_jz ;
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
        obj.picture = testobj.pic_jz ;
      }else {
        Toast(data.message);
      }  
        
    },

    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_txrjzgl',
        qs.stringify({
          listidnum :this.listIdNum
        })
      );
      if (data.flag === '0') {
        //this.items = data.data;
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data; 
      }
    },
  
    async submit(){
      if(this.state){
        return false;
      }
      this.state = true;

      /*for(var j = 0; j < this.items.length; j++) {
        if(this.items[j].state === '1'){
            this.listIdNum += this.items[j].certificate_code+',';
        }        
      }*/

      //alert("入住提交");
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_roomsq',
        qs.stringify({
          IdNum: this.IdNum,
          listIdNum :this.listIdNum,
          SiteCode:this.SiteCode,
          ClientID:this.ClientID
        })
      );
      if (data.flag === '0') {
          this.state = false;
          Dialog.confirm({
            showCancelButton: false,
            message: data.message
          }).then(async () => {
            window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
          });
      }else {
        this.state = false;
        Toast(data.message);
      }    
        
    }
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 44.1px;
      height: 44.1px;
      margin: 10px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 50%;
    }
    &.yy {
      width: 15%;
    }
    &.yy2 {
      width: 10%;     
    }
    &.yy1 {
      width: 25%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 30px;
      }
      .headline {
        font-size: 26px;
        color: rgb(168, 169, 170);     
        &.h1 {
          margin-right: 30px;
        }
        &.h2 {
          float: right;
        }
        &.h3 {
          margin-right: 35px;
        }
      }
      .imgpic {
        display: block;
        width: 310px;  
        height: 196px;  
        float: right;
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
      }
      .roomcode {
        margin-right: 80px;
        &.f {
          font-size: 30px;
          color: rgb(168, 169, 170);
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        width: 160px;
        border: 0;
        color: #333;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 50px;
        width: 50px;
        float: right;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 55px;
        border-radius: 15px;
        &.xie {
          background: rgb(170, 149, 207);
        }
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 50px;
          display: block;
          margin-left: 9px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 20px;
        }
      }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
